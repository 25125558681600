import React from "react";
import { FaSearch } from 'react-icons/fa';

const HeroSearch = ({ language, t, handleSearchSubmit, placeholder }) => {
  return (
    <div className="box mb-8">
      <div className="bg-green-600 text-white p-6 rounded-md">
        <h3 className="text-2xl font-bold mb-4">{t.searchData}</h3>
        <form onSubmit={handleSearchSubmit}>
          <div className="flex">
            <input
              type="text"
              placeholder={`${t.searchPlaceholder}${placeholder}`}
              className="flex-grow px-4 py-2 rounded-l-md focus:outline-none"
            />
            <button type="submit" className="px-4 py-5 bg-white text-green-600 rounded-r-md">
              {/* <i className="fa fa-search"></i> */}
              <FaSearch />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default HeroSearch;