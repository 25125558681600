import React from "react";
import { FaSearch } from 'react-icons/fa';

const Header = ({ language, t, handleSearchSubmit, handleLanguageChange }) => {
  return (
    <div className="bg-green-600 py-4" style={{ background: "#008244 url(./bg.png)" }}>
      <div className="container mx-auto flex items-center">
        <a href="/"><img src="https://data.wahooas.org/tenders/assets/ecowas-426d9cd94715e4cf9986f9bb2a3d12e69ed06c989c22b2eb9a664207b8725942.png" alt="Logo" className="h-16 mr-4" style={{borderRadius: "em"}} /></a>
        <nav className="flex space-x-4">
          <a href="/bulletins" className="text-white hover:underline">{t.bulletins}</a>
        </nav>
        <nav className="flex space-x-4" style={{color: "white", fontSize: "150%"}}>
          &nbsp;|&nbsp;
        </nav>
        <nav className="flex space-x-4">
          <a href="/publications" className="text-white hover:underline">{t.publications}</a>
        </nav>
        <nav className="flex space-x-4" style={{color: "white", fontSize: "150%"}}>
          &nbsp;|&nbsp;
        </nav>
        <nav className="flex space-x-4">
          <a href="/publications" className="text-white hover:underline">{t.resources}</a>
        </nav>
        <form id="navSearch" className="ml-auto flex" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder={t.search}
            className="px-4 py-2 rounded-l-md focus:outline-none"
          />
          <button type="submit" className="px-4 py-2 bg-white text-green-600 rounded-r-md">
            {/* <i className="fa fa-search"></i> */}
            <FaSearch />
          </button>
        </form>
        <div className="ml-4">
          <select className="p-2.5 rounded-md" value={language} onChange={(e) => handleLanguageChange(e.target.value)}>
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="pt">Português</option>
          </select>
        </div>
      </div>
    </div>
  );
};
export default Header;