import React, {useState} from "react";

const AppCard = ({ title, description, imageUrl, imageAlt, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const descriptionLines = description.split('\n');
  const lastLine = descriptionLines[descriptionLines.length - 1];

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className={`box rounded-md shadow-md ${isHovered ? 'bg-gray-900' : 'bg-white'}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="p-6">
          <h1 className={`text-2xl font-bold mb-2 ${isHovered ? 'text-white' : ''}`} style={isHovered ? {} : {color: '#8E1D36'}}>{title}</h1>
          <p className={`text-xs ${isHovered ? 'text-white' : 'text-gray-700'}`}>
            {descriptionLines.slice(0, -1).join('\n')}
            <span className={`${isHovered ? 'underline decoration-gold' : ''}`}>{lastLine}</span>
          </p>
        </div>
        <hr className="py-2" />
        <div className="relative box1" >
          {/* <a href={link} target="_blank"> */}
          <img src={imageUrl} alt={imageAlt} className="w-full" />
          <h2 className={`absolute bottom-0 left-0 right-0 bg-opacity-80 p-4 text-sm ${isHovered ? 'bg-gray-900 text-white' : 'text-white'}`} style={isHovered ? {} : { background: "#8E1D36",opacity: "0.8"}}>
            {title}
          </h2>
          {/* </a> */}
        </div>
      </div>
    </a>
  );
};

export default AppCard;