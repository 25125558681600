import React from "react";

const Footer = ({ language, t }) => {
  return <>
    <div className="text-white py-8" style={{ background: "#8E1D36" }}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">{t.about}</h3>
            <p className="text-white">
              {t.aboutText}
            </p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">{t.quickLinks}</h3>
            <ul className="space-y-2">
              <li><a href="/" className="text-white hover:text-white">Home</a></li>
              <li><a href="/about" className="text-white hover:text-white">About Us</a></li>
              <li><a href="/programs" className="text-white hover:text-white">Programs</a></li>
              <li><a href="/resources" className="text-white hover:text-white">Resources</a></li>
              <li><a href="/contact" className="text-white hover:text-white">Contact Us</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">{t.contactInfo}</h3>
            <p className="text-white">
              {t.contactText}
            </p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">{t.subscribe}</h3>
            <form>
              <div className="flex">
                <input
                  type="email"
                  className="w-full px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-500 rounded-r-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="text-white py-4" style={{ background: "#008244" }}>
      <div className="container mx-auto px-4">
        <div className="text-center">
          <p className="text-white">&copy; {new Date().getFullYear()} {t.copyright}</p>
        </div>
      </div>
        <div className="text-right">
          <p className="text-white builtBy" dangerouslySetInnerHTML={{__html: t.builtBy}} />
        </div>
    </div>
  </>;
};
export default Footer;