import React, { useState, useEffect } from 'react';
import ModalMessage from './components/ModalMessage';
import AppCard from './components/AppCard';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroSearch from './components/HeroSearch';

const translations = {
  en: {
    title: 'ECOWAS Health Data Platform',
    datasets: 'Datasets',
    bulletins: "Bulletins",
    publications: "Publications",
    resources: "Resources",
    search: 'Search',
    searchData: 'Search data',
    searchPlaceholder: 'E.g. ',
    comingSoon: {
      title: 'Coming Soon',
      message: 'This feature is currently under development and will be available soon.',
      closeButton: 'Close',
    },
    appCards: [
      {
        title: 'ECOWAS Health Data Warehouse',
        description: 'ECOWAS Regional Data Warehouse for Health Information Sharing is the fulcrum of \nour data efforts at WAHO.',
        imageUrl: "/venus.png",
        imageAlt: 'The ECOWAS Health Data Warehouse',
        link: "https://data.wahooas.org/dhis/",
      },
      {
        title: 'ECOWAS Epidemic Outbreaks Dashboard',
        description: 'The ECOWAS Epidemic Outbreaks Dashboard tracks ongoing and historical Epidemic \noutbreaks of priority diseases across the community.',
        imageUrl: "/outbreaks.png",
        imageAlt: 'ECOWAS Epidemic Outbreaks Dashboard',
        link: "https://data.wahooas.org/outbreaks/",
      },
      {
        title: 'ECOWAS Regional Health Scorecard',
        description: 'ECOWAS Regional Health Scorecard tracks key indicators of health, the Sustainable Development Goals and other global and regional health declarations as they pertain \nto the ECOWAS community.',
        imageUrl: "/rho.png",
        imageAlt: 'ECOWAS Regional Health Scorecard',
        link: "https://data.wahooas.org/scorecard/",
      },
      {
        title: 'ECOWAS eCTD',
        description: 'Electronic Common Technical Document is the preferred format for submitting applications, amendments, supplements, and reports to WAHO\'s Center for Drug \nEvaluation and Research and Center for Biologics Evaluation and Research.',
        imageUrl: "/ectd.png",
        imageAlt: 'Electronic Common Technical Document (eCTD)',
        link: "https://ectd.wahooas.org/",
      },
      {
        title: 'e-Learning',
        description: 'The WAHO e-Learning platform offers a variety of online courses and training programs aimed at improving the skills and knowledge of healthcare professionals within the ECOWAS community',
        imageUrl: "/elearning.png",
        imageAlt: 'Electronic Common Technical Document (eCTD)',
        link: "https://resnmp.wahooas.org/",
      },
      {
        title: 'Emergency Workforce Database',
        description: 'The WAHO Emergency Workforce Database is a comprehensive repository of healthcare professionals and emergency responders available for deployment during health crises and emergencies within the ECOWAS community.',
        imageUrl: "/workforce.png",
        imageAlt: 'Emergency Workforce Database',
        link: "https://workforce.wahooas.org/",
      }
    ],
    placeholders: ["Malaria", "Maternal and Child Health", "Meningitis", "Dengue", "HIV", "Nutrition"],
    footer: {
      about: 'About Us',
      aboutText: 'We are a Specialized Institution of the Economic Community of West African States (ECOWAS) responsible for health issues.',
      quickLinks: 'Quick Links',
      contactInfo: 'Contact Info',
      contactText: <>West African Health Organization<br />
        01 BP 153 Bobo-Dioulasso 01 / Burkina Faso<br />
        Email: wahooas@wahooas.org<br />
        Phone: +226 20 97 01 00 / +226 20 97 57 75</>,
      subscribe: 'Subscribe to our Epi Bulletins',
      copyright: 'The West African Health Organization. All rights reserved.',
      builtBy: 'Built with <svg style=\"display: inline\" height=\"14\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 8 8\">  <path d=\"M4 1.5C3.3 0.9 2.3 0.7 1.5 1.1C0.7 1.5 0.2 2.4 0.3 3.3C0.4 4.2 1.5 5.4 4 6.9C6.5 5.4 7.6 4.2 7.7 3.3C7.8 2.4 7.3 1.5 6.5 1.1C5.7 0.7 4.7 0.9 4 1.5Z\" fill=\"red\"/></svg> by the WAHIT Team - Health Information Systems (HIS) Unit'
    },
  },
  fr: {
    title: 'Plateforme de données sur la santé de la CEDEAO',
    datasets: 'Ensembles de données',
    search: 'Rechercher',
    bulletins: "Bulletins",
    publications: "Publications",
    resources: "Ressources",
    searchData: 'Rechercher des données',
    searchPlaceholder: 'Par exemple : ',
    comingSoon: {
      title: 'Bientôt disponible',
      message: 'Cette fonctionnalité est en cours de développement et sera bientôt disponible.',
      closeButton: 'Fermer',
    },
    appCards: [
      {
        title: 'Entrepôt de données sur la santé de la CEDEAO',
        description: 'L\'entrepôt régional de données de la CEDEAO pour le partage d\'informations sur la \nsanté est le pivot de nos efforts en matière de données à l\'OOAS.',
        imageUrl: "/venus.png",
        imageAlt: 'L\'entrepôt de données sur la santé de la CEDEAO',
        link: "https://data.wahooas.org/dhis/",
      },
      {
        title: 'Tableau de bord des épidémies de la CEDEAO',
        description: 'Le tableau de bord des épidémies de la CEDEAO suit les épidémies en cours et \nhistoriques des maladies prioritaires dans la communauté.',
        imageUrl: "/outbreaks.png",
        imageAlt: 'Tableau de bord des épidémies de la CEDEAO',
        link: "https://data.wahooas.org/outbreaks/",
      },
      {
        title: 'Tableau de bord de la santé de la CEDEAO',
        description: 'Le tableau de bord de la santé régionale de la CEDEAO suit les indicateurs clés de la santé, les objectifs de développement durable et d\'autres déclarations mondiales et \nrégionales sur la santé concernant la communauté de la CEDEAO.',
        imageUrl: "/rho.png",
        imageAlt: 'Tableau de bord de la santé régionale de la CEDEAO',
        link: "https://data.wahooas.org/scorecard/",
      },
      {
        title: 'eCTD de la CEDEAO',
        description: 'Le Common Technical Document électronique est le format privilégié pour soumettre les demandes, les amendements, les suppléments et les rapports au Centre d\'évaluation des médicaments et des produits biologiques de l\'OOAS.',
        imageUrl: "/ectd.png",
        imageAlt: 'Document technique commun électronique (eCTD)',
        link: "https://ectd.wahooas.org/",
      },
      {
        title: 'e-Learning',
        description: 'La plateforme d\'apprentissage en ligne de la CEDEAO propose une variété de cours et de programmes de formation en ligne visant à améliorer les compétences et les connaissances des professionnels de la santé de la région de la CEDEAO.',
        imageUrl: "/elearning.png",
        imageAlt: 'e-Learning',
        link: "https://resnmp.wahooas.org/",
      },
      {
        title: 'Base de Données du Personnel d\'Urgence',
        description: 'La base de données du personnel d\'urgence de la CEDEAO est un référentiel complet des professionnels de la santé et des intervenants d\'urgence disponibles pour être déployés lors de crises sanitaires et d\'urgences dans la région de la CEDEAO.',
        imageUrl: "/workforce.png",
        imageAlt: 'Base de Données du Personnel d\'Urgence',
        link: "https://workforce.wahooas.org/",
      }
    ],
    placeholders: ["Paludisme", "Santé maternelle et infantile", "Méningite", "Dengue", "VIH", "Nutrition"],
    footer: {
      about: 'À propos de nous',
      aboutText: 'Nous sommes une institution spécialisée de la Communauté économique des États de l\'Afrique de l\'Ouest (CEDEAO) chargée des questions de santé.',
      quickLinks: 'Liens rapides',
      contactInfo: 'Informations de contact',
      contactText: <>Organisation Ouest Africaine de la Santé<br />
        01 BP 153 Bobo-Dioulasso 01 / Burkina Faso<br />
        Email: wahooas@wahooas.org<br />
        Téléphone: +226 20 97 01 00 / +226 20 97 57 75</>,
      subscribe: 'Abonnez-vous à nos bulletins épidémiologiques',
      copyright: 'L\'Organisation Ouest Africaine de la Santé. Tous droits réservés.',
      builtBy: 'Construit avec <svg style=\"display: inline\" height=\"14\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 8 8\">  <path d=\"M4 1.5C3.3 0.9 2.3 0.7 1.5 1.1C0.7 1.5 0.2 2.4 0.3 3.3C0.4 4.2 1.5 5.4 4 6.9C6.5 5.4 7.6 4.2 7.7 3.3C7.8 2.4 7.3 1.5 6.5 1.1C5.7 0.7 4.7 0.9 4 1.5Z\" fill=\"red\"/></svg>par l\'équipe WAHIT - Unité des systèmes d\'information de santé (SIS)'
    },
  },
  pt: {
    title: 'Plataforma de Dados de Saúde da CEDEAO',
    datasets: 'Conjuntos de Dados',
    bulletins: "Boletins",
    publications: "Publicações",
    resources: "Recursos",
    search: 'Pesquisar',
    searchData: 'Pesquisar dados',
    searchPlaceholder: 'Por exemplo: ',
    comingSoon: {
      title: 'Em breve',
      message: 'Este recurso está atualmente em desenvolvimento e estará disponível em breve.',
      closeButton: 'Fechar',
    },
    appCards: [
      {
        title: 'Armazém de Dados de Saúde da CEDEAO',
        description: 'O Armazém Regional de Dados da CEDEAO para Compartilhamento de Informações \nde Saúde é o fulcro dos nossos esforços de dados na OOAS.',
        imageUrl: "/venus.png",
        imageAlt: 'O Armazém de Dados de Saúde da CEDEAO',
        link: "https://data.wahooas.org/dhis/",
      },
      {
        title: 'Painel de Surtos Epidêmicos da CEDEAO',
        description: 'O Painel de Surtos Epidêmicos da CEDEAO rastreia surtos epidêmicos em \nandamento e históricos de doenças prioritárias em toda a comunidade.',
        imageUrl: "/outbreaks.png",
        imageAlt: 'Painel de Surtos Epidêmicos da CEDEAO',
        link: "https://data.wahooas.org/outbreaks/",
      },
      {
        title: 'Scorecard Regional de Saúde da CEDEAO',
        description: 'O Scorecard Regional de Saúde da CEDEAO rastreia indicadores-chave de saúde, os Objetivos de Desenvolvimento Sustentável e outras declarações globais e regionais \nde saúde relevantes para a comunidade da CEDEAO.',
        imageUrl: "/rho.png",
        imageAlt: 'Scorecard Regional de Saúde da CEDEAO',
        link: "https://data.wahooas.org/scorecard/",
      },
      {
        title: 'eCTD da CEDEAO',
        description: 'O Documento Técnico Comum Eletrônico é o formato preferido para enviar aplicativos, emendas, suplementos e relatórios ao Centro de Avaliação de \nMedicamentos e ao Centro de Avaliação de Produtos Biológicos da OOAS.',
        imageUrl: "/ectd.png",
        imageAlt: 'Documento Técnico Comum Eletrônico (eCTD)',
        link: "https://ectd.wahooas.org/",
      },
      {
        title: 'e-Learning',
        description: 'A plataforma de e-Learning da CEDEAO oferece uma variedade de cursos online e programas de treinamento voltados para melhorar as habilidades e conhecimentos dos profissionais de saúde na região da CEDEAO.',
        imageUrl: "/elearning.png",
        imageAlt: 'Electronic Common Technical Document (eCTD)',
        link: "https://resnmp.wahooas.org/",
      },
      {
        title: 'Base de dados de pessoal de emergência',
        description: 'O Base de dados de pessoal de emergência da CEDEAO é um repositório abrangente de profissionais de saúde e socorristas disponíveis para implantação durante crises de saúde e emergências na região da CEDEAO.',
        imageUrl: "/workforce.png",
        imageAlt: 'Base de dados de pessoal de emergência',
        link: "https://workforce.wahooas.org/",
      }
    ],
    placeholders: ["Malária", "Saúde Materna e Infantil", "Meningite", "Dengue", "HIV", "Nutrição"],
    footer: {
      about: 'Sobre Nós',
      aboutText: 'Somos uma Instituição Especializada da Comunidade Económica dos Estados da África Ocidental (CEDEAO) responsável por questões de saúde.',
      quickLinks: 'Links Rápidos',
      contactInfo: 'Informações de Contato',
      contactText: <>Organização Oeste Africana da Saúde<br />
        01 BP 153 Bobo-Dioulasso 01 / Burkina Faso<br />
        Email: wahooas@wahooas.org<br />
        Telefone: +226 20 97 01 00 / +226 20 97 57 75</>,
      subscribe: 'Assine nossos Boletins Epidemiológicos',
      copyright: 'A Organização Oeste Africana da Saúde. Todos os direitos reservados.',
      builtBy: 'Criado com <svg style=\"display: inline\" height=\"14\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 8 8\">  <path d=\"M4 1.5C3.3 0.9 2.3 0.7 1.5 1.1C0.7 1.5 0.2 2.4 0.3 3.3C0.4 4.2 1.5 5.4 4 6.9C6.5 5.4 7.6 4.2 7.7 3.3C7.8 2.4 7.3 1.5 6.5 1.1C5.7 0.7 4.7 0.9 4 1.5Z\" fill=\"red\"/></svg> pela Equipa WAHIT - Unidade de Sistemas de Informação em Saúde (SIS)'
    },
  },
};


const HomePage = () => {
  const [placeholder, setPlaceholder] = useState('');
  const [language, setLanguage] = useState('en');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    const placeholders = translations[language].placeholders;
    const randomIndex = Math.floor(Math.random() * placeholders.length);
    setPlaceholder(placeholders[randomIndex]);
  }, [language]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const t = translations[language];

  return (
    <div >
      <Header language={language} t={t} handleSearchSubmit={handleSearchSubmit} handleLanguageChange={handleLanguageChange} />

      <div className="bg-white py-8">
        <div className="container mx-auto">
          <HeroSearch language={language} t={t} handleSearchSubmit={handleSearchSubmit} placeholder={placeholder} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {t.appCards && Object.values(t.appCards).map(
              (appCard, idx) => <AppCard
                title={appCard.title}
                key={idx}
                description={appCard.description}
                imageUrl={appCard.imageUrl}
                imageAlt={appCard.imageAlt}
                link={appCard.link}
              />
            )}
          </div>
        </div>
      </div>
      <Footer language={language} t={translations[language].footer} />
      <ModalMessage isOpen={isModalOpen} onClose={handleCloseModal} message={t.comingSoon.message} title={t.comingSoon.title} closeButton={t.comingSoon.closeButton} />
    </div>
  );
};

export default HomePage;